import React, { useEffect, useState } from 'react'
import { Alert, Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Table, message as waw } from 'antd';
import { motion } from "framer-motion";
import DetailRequest from './DetailRequest';
import NavbarReq from './NavbarReq';
// import CheckBox from './Checkbox';
import { Tabs } from 'antd';
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { rulesGeneral, toRp } from '../../helpers/general_helper';
import { env } from '../../configs/EnvironmentConfig';
import InputCurrencyHelper from '../../helpers/input_currency_helper';
import { formatterNumber, parserNumber } from '../../helpers/input_format';
import axios from 'axios';
import dayjs from 'dayjs';


const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const RequestMaterial = (props,{ callback }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const showModal = () => {
      setIsModalVisible(true);
    };
  
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };



    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
  
    const [formInputs, setFormInputs] = useState([{ id: 1, value: '' }]);
    const items = ['Item 1', 'Item 2', 'Item 3'];
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
  
    const handleAddInput = () => {
      const newInput = {
        id: formInputs.length + 1,
        value: ''
      };
      setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length);
      setFormInputs([...formInputs, newInput]);
    };
  
  
    const handleInputChange = (id, value) => {
      const updatedInputs = formInputs.map(input => {
        if (input.id === id) {
          return { ...input, value };
        }
        return input;
      });
  
      setFormInputs(updatedInputs);
    };

    const {
        otherSignIn,
        showForgetPassword,
        hideAuthMessage,
        onForgetPasswordClick,
        showLoading,
        extra,
        showMessage,
        message,
        authenticated,
        showAuthMessage,
        token,
        redirect,
        allowRedirect,
      } = props;

      const imgLogoStyle = {
        marginTop: "30px",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
      };


  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [orderStatus, setOrderStatus] = useState('confirm','loading','success');
  const [resultModalVisible, setResultModalVisible] = useState(false);

const handleConfirmOrder = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setOrderStatus('confirm');
      waw.success('Request Berhasil Di Ajukan');
      setResultModalVisible(true); 
      window.location = '/';
    }, 2000);
  };

  const handleResultModalClose = () => {
    setResultModalVisible(false);
    handleCancel(); 
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [form] = Form.useForm();
  const [apiData, setApiData] = useState([]);
  const [formData, setFormData] = useState({});


  const onFinishRequest = async () => {
    const values = await form.validateFields();
    setFormData(values);
    values.total_price_all = Object.keys(values).length === 0 ? '-' : values?.items?.length > 0 ? values?.items?.reduce((a,b) => a + (parseInt(b.total_price) || 0) ,0) : 0;
    setIsModalVisible(true);
  }

  const handleSubmit = async () => {
    try {
      setLoadingRequest(true)
     const values = await form.validateFields();
    setFormData(values);
    values.total_price_all = Object.keys(values).length === 0 ? '-' : values?.items?.length > 0 ? values?.items?.reduce((a,b) => a + (parseInt(b.total_price) || 0) ,0) : 0;
      const response = await fetch(env.API + "/core/request-quotation", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      setLoadingRequest(false);
      if (response.status === 200) {
        waw.success('Data Berhasil Dikirim');
        form.resetFields();
        handleRemoveInput();
        setIsModalVisible(false);
      } else {
        console.error('Gagal Dikirim', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

const handlePricePerUnitChange = (value, index) => {
  const jumlahItem = form.getFieldsValue(['items', index, 'jumlah_permintaan']);
  console.log('handleprice change',jumlahItem.items,index)
  calculatePPNAndTotal(value, jumlahItem?.items[index]?.jumlah_permintaan, index);
};

const handleJumlahItemChange = (value, index) => {
  const hargaPerUnit = form.getFieldsValue(['items', index, 'price_per_unit']);
  console.log('handlejumlah item change',hargaPerUnit.items,index)
  calculatePPNAndTotal(hargaPerUnit?.items[index]?.price_per_unit, value, index);
};


const calculatePPNAndTotal = (price_per_unit, jumlah_permintaan, index) => {
  if (price_per_unit && jumlah_permintaan) {
    const hargaPerUnitFloat = parseFloat(price_per_unit);
    const jumlahItemInt = parseInt(jumlah_permintaan);
    const totalHarga = hargaPerUnitFloat * jumlahItemInt;
    form.setFieldsValue({
      items: [
        ...form.getFieldValue('items').map((item, idx) =>
          idx === index
            ? { ...item, total_price: totalHarga.toFixed(0) }
            : item
        )
      ]
    });
  } else {
    form.setFieldsValue({
      items: [
        ...form.getFieldValue('items').map((item, idx) =>
          idx === index ? { ...item, total_price: undefined } : item
        )
      ]
    });
  }
};

  const columns = [
    {
      title: 'Item Permintaan',
      dataIndex: 'item_permintaan',
      key: 'item_permintaan'
    },
    {
      title: 'Jumlah Permintaan',
      dataIndex: 'jumlah_permintaan',
      key: 'jumlah_permintaan'
    },
    {
      title: 'Satuan',
      dataIndex: 'satuan',
      key: 'satuan'
    },
    {
      title: 'Harga Per Unit',
      dataIndex: 'price_per_unit',
      key: 'price_per_unit',
      render: (price_per_unit) => `Rp.${price_per_unit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
    },
    {
      title: 'Total Harga',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (total_price) => `Rp.${total_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
    },
    {
      title: 'Tanggal Pengiriman',
      dataIndex: 'request_date',
      key: 'request_date',
      render: (request_date) => formatDate(request_date),
    },
  ];

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };
  
  const [datePicker, setDatePicker] = useState(null);
  const handleDateChange = (date, dateString) => {
    console.log('Selected Date:', dateString);
    setDatePicker(dateString)
  };
  
  const [formTwo] = Form.useForm();
  const [isModalVisibleTwo, setIsModalVisibleTwo] = useState(false);
  const [formDataTwo, setFormDataTwo] = useState({});
  const [apiDataTwo, setApiDataTwo] = useState([]);
  const [totalHarga, setTotalHarga] = useState(null);

  const onFinish = async (values) => {
    console.log('Received values:', values);
    setIsModalVisibleTwo(true);
  }

  const handleModalCancelTwo = () => {
    setIsModalVisibleTwo(false);
  };

  const handleModalOk = async () => {
    try {
      setLoading(true);
      const values = await formTwo.validateFields();
      setFormDataTwo(values);
      const response = await fetch(env.API + "/core/purchase-request", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      setLoading(false);
      if (response.status === 200) {
        waw.success('Data Berhasil Dikirim');
        formTwo.resetFields();
        handleRemoveInput();
        setIsModalVisibleTwo(false);
      } else {
        console.error('Gagal Dikirim', response.statusText);
      }
    } catch (error) {
      console.error('Kesalahan:', error.message);
    }
  };

  const [quotationNumber, setQuotationNumber] = useState('');
  const [totalPrice, setTotalPrice] = useState(null);
  useEffect(() => {
    if (quotationNumber !== '') {
      fetchTotalPrice(quotationNumber);
    }
  }, [quotationNumber]);

  const fetchTotalPrice = async (no_quotation) => {
    try {
      const response = await fetch(env.API + `/core/price-quotation?no_quotation=${no_quotation}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTotalPrice(data.data.total_price_all);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleQuotationChange = (event) => {
    setQuotationNumber(event.target.value);
  };

  const [inputCount, setInputCount] = useState(1);

  const handleAddInputItem = () => {
    if (inputCount < 15) {
      setInputCount(prevCount => prevCount + 1);
    } else {
      message.error('Anda telah mencapai batas maksimal 15 item.');
    }
  };

  const handleRemoveInput = () => {
    if (inputCount > 1) {
    setInputCount(prevCount => prevCount - 1);
   const values = form.getFieldValue('items');
       form.setFieldsValue({
         items: values.filter((_, i) => i !== inputCount - 1)
       });
   }
   };

   const rulesEmail = () => [
    {
      type: 'email',
      message: 'Format email tidak valid',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || value.endsWith('@toyota.co.id')) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Alamat email hanya boleh dari "@toyota.co.id"'));
      },
    }),
  ];

  const [divisi, setDivisi] = useState([]);
  const getDivisi = async () => {
    try {
      const response = await axios.get(env.API + "/core/admin/divisi");
      setDivisi(response.data.data || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const [jenisKebutuhan, setKebutuhan] = useState([]);
  const getJenisKebutuhan = async () => {
    try {
      const response = await axios.get(env.API + "/core/admin/jenis-kebutuhan");
      setKebutuhan(response.data.data || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {    
    getJenisKebutuhan();
    getDivisi();
  }, []);
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  const [selectedCategory, setSelectedCategory] = useState('NON MATERIAL'); 

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };
  return (
    <>
{activeTab === 1 && (
    <>
        <Form 
        form={form}
        layout="vertical" 
        name="request-form"
        onFinish={onFinishRequest}
        autoComplete="off"
        >
    <div className=''>
        <div className='header-from-input-wraper-content'>
            <div className=''>
                <div className='header-from-input-wraper-title'>
                  <div className='header-form-wraper-border-inline'>
                  <div className='header-form-wraper-border'>
                    <div className='header-from-input-wraper-inline'>
                <div className=''>
                  <h1 style={{fontSize:'18px',fontWeight:'600',color:'black'}}>Form Pengajuan</h1>
                  <p style={{color:'#6F6C6C',fontSize:'13px'}}>Berikut ini dapat diinputkan data Nomor Pengajuan Pembelian dan Juga Harga kepada koperasi toyota.</p>
                </div>
               
                <Row gutter={[16, 16]} justify="start" style={{marginTop:'30px'}}>
                <Col xs={24} sm={12}>
                <Form.Item
                            label="Kategori"
                            hasFeedback
                            name="kategori"
                            // name={['items', index, 'satuan']}
                            rules={rulesGeneral()}
                        >
                            <Select style={{ width: "100%" }}
                              onChange={handleCategoryChange}
                              placeholder="Pilih Kategori"
                              defaultValue={'NON MATERIAL'}
                              >
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="NON MATERIAL">NON MATERIAL</Option>
                            <Option value="MATERIAL">MATERIAL</Option>
                            </Select>
                        </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                <Form.Item
                        hasFeedback
                        label="Judul Pengajuan"
                        name="judul_pengajuan"
                        rules={rulesGeneral()}
                    >
                        <Input
                         placeholder="Masukan Judul"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                <Form.Item
                        hasFeedback
                        label="Pemohon"
                        name="applicant"
                        rules={rulesGeneral()}
                    >
                        <Input
                         placeholder="Masukan Nama Pemohon"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                <Form.Item
                        hasFeedback
                        label="Alamat Email"
                        name="email_address"
                        rules={rulesGeneral()}
                    >
                        <Input
                           placeholder="Masukan Alamat Email"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                <Form.Item
                        hasFeedback
                        name="division"
                        label="Divisi"
                        rules={rulesGeneral()}
                        >
                        <Select style={{ width: "100%" }}  placeholder="Pilih Salah Satu">
                        <Option value="" disabled>
                            -- Pilih --
                        </Option>
                        {divisi.map((nama_divisi) => {
                          return (
                        <Option value={nama_divisi.id}>
                          {nama_divisi.nama_divisi}
                        </Option>
                        );
                        })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                <Form.Item
                        hasFeedback
                        name="jenis_kebutuhan"
                        label="Jenis Kebutuhan"
                        rules={rulesGeneral()}
                        >
                        <Select style={{ width: "100%" }}  placeholder="Pilih Salah Satu">
                        <Option value="" disabled>
                            -- Pilih --
                        </Option>
                        {jenisKebutuhan.map((jenis_kebutuhan) => {
                          return (
                        <Option value={jenis_kebutuhan.id}>
                          {jenis_kebutuhan.nama_jenis_kebutuhan}
                        </Option>
                        );
                        })}
                        </Select>
                    </Form.Item>
                </Col>
                {selectedCategory === 'MATERIAL' && (
                    <>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          hasFeedback
                          label="Department"
                          name="departemen"
                          rules={rulesGeneral()}
                        >
                           <Select style={{ width: "100%" }}
                              placeholder="Pilih Department">
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="HRGA">HRGA</Option>
                            </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12}>
                        <Form.Item
                          hasFeedback
                          label="Shift"
                          name="shift"
                          rules={rulesGeneral()}
                        >
                         <Select style={{ width: "100%" }}
                              placeholder="Pilih Shift">
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="NON">Non</Option>
                            </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12}>
                        <Form.Item
                          hasFeedback
                          label="Lokasi"
                          name="location"
                          rules={rulesGeneral()}
                        >
                          <Select style={{ width: "100%" }}
                              placeholder="Pilih Lokasi">
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="JAKARTA">Jakarta</Option>
                            </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          hasFeedback
                          label="Vendor"
                          name="vendor"
                          rules={rulesGeneral()}
                        >
                          <Select style={{ width: "100%" }}
                              placeholder="Pilih Vendor">
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="PT AGUNG BERSAMA">PT. Agung Bersama</Option>
                            </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
                <div className='header-from-input-wraper-line-loop mt-3'></div>
                {selectedCategory === 'NON MATERIAL' && (
                <>
                {[...Array(inputCount)].map((_, index) => (
                  <>
                  <div style={{display:'flex'}}>
                        <h1 style={{fontSize:'13px',marginTop:'28px',marginLeft:'0px'}}>{`Item ${index + 1}`}</h1>
                        </div>
                   <Row gutter={[16, 16]} justify="start" style={{marginTop:'10px'}}>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Item Kebutuhan</h5>
                        <Form.Item
                            hasFeedback
                            // name="item_permintaan"
                            name={['items', index, 'item_permintaan']}
                            rules={rulesGeneral()}
                        >
                            <Input 
                            placeholder="Masukan Item Kebutuhan"
                            />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Jumlah</h5>
                        <Form.Item
                            hasFeedback
                            // name="jumlah_permintaan"
                            name={['items', index, 'jumlah_permintaan']}
                            rules={[
                              {
                                required: true,
                                message: "Jumlah permintaan tidak boleh kosong",
                              },
                              {
                                pattern: /^[0-9]*$/,
                                message: "Jumlah permintaan hanya boleh berisi angka",
                              },
                            ]}
                        >
                            <Input 
                             placeholder="Masukan Jumlah"
                            onChange={(e) => handleJumlahItemChange(e.target.value,index)}
                             onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                              e.preventDefault();
                              waw.error('Hanya diperbolehkan memasukkan angka!');
                            }
                          }}
                            />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Satuan</h5>
                        <Form.Item
                            hasFeedback
                            // name="satuan"
                            name={['items', index, 'satuan']}
                            rules={rulesGeneral()}
                        >
                            <Select style={{ width: "100%" }}  placeholder="Masukan Satuan">
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="PCS">PCS</Option>
                            <Option value="UNIT">UNIT</Option>
                            <Option value="PACK">PACK</Option>
                            <Option value="LOT">LOT</Option>
                            <Option value="AU">AU</Option>
                            <Option value="TBG">TBG</Option>
                            </Select>
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Harga Per Unit</h5>
                        <Form.Item
                            hasFeedback
                            // name="price_per_unit"
                            name={['items', index, 'price_per_unit']}
                            rules={[
                              {
                                required: true,
                                message: "Harga Per Unit tidak boleh kosong",
                              },
                              {
                                pattern: /^[0-9]*$/,
                                message: "Harga Per Unit hanya boleh berisi angka",
                              },
                            ]}
                            initialValue={''}
                            
                        >
                            <InputNumber 
                            style={{width:'100%'}}
                            controls={false}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            onChange={(value) => handlePricePerUnitChange(value ? value : [],index)}
                            placeholder="Masukan Harga Per Unit"
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                e.preventDefault();
                                waw.error('Hanya diperbolehkan memasukkan angka!');
                              }
                            }}
                            />
                            {/* <Input
                            formatter={toRp}
                             onChange={(e) => handlePricePerUnitChange(e.target.value)}
                            /> */}
                            {/* <InputCurrencyHelper
                            callback={(value) => handlePricePerUnitChange(value ? value : [])}
                            values='price_per_unit'
                            /> */}
                        </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Fee</h5>
                        <Form.Item
                            hasFeedback
                            placeholder="PPN"
                            name="ppn"
                            rules={rulesGeneral()}
                        >
                            <InputNumber 
                            style={{width:'100%'}}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            disabled
                            />
                        </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Total Harga</h5>
                        <Form.Item
                            hasFeedback
                            placeholder="Input Total Harga"
                            name={['items', index, 'total_price']}
                            rules={rulesGeneral()}
                        >
                            <InputNumber 
                            style={{width:'100%'}}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            disabled
                            />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Tanggal Pengiriman</h5>
                        <Form.Item
                            name={['items', index, 'request_date']}
                            rules={[
                                {
                                required: true,
                                message: "Tanggal pengiriman tidak boleh kosong",
                                },
                            ]}
                            initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                            >
                                <DatePicker 
                                picker="date" 
                                onChange={handleDateChange} 
                                placeholder='Pilih Tanggal'
                                format="YYYY-MM-DD"
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>
                        </Row>
                  </>
                ))}
                </>
                )}

                {selectedCategory === 'MATERIAL' && (
                <>
                {[...Array(inputCount)].map((_, index) => (
                  <>
                  <div style={{display:'flex'}}>
                        <h1 style={{fontSize:'13px',marginTop:'28px',marginLeft:'0px'}}>{`Item ${index + 1}`}</h1>
                        </div>
                   <Row gutter={[16, 16]} justify="start" style={{marginTop:'10px'}}>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Item Kebutuhan</h5>
                        <Form.Item
                            hasFeedback
                            // name="item_permintaan"
                            // name={['items', index, 'item_permintaan']}
                            rules={rulesGeneral()}
                        >
                            <Select style={{ width: "100%" }}
                              placeholder="Pilih Item Kebutuhan">
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="KEMEJA">KEMEJA</Option>
                            <Option value="KAOS">KAOS</Option>
                            <Option value="CELANA">CELANA</Option>
                            </Select>
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Item No.</h5>
                        <Form.Item
                            hasFeedback
                            // name="item_permintaan"
                            // name={['items', index, 'item_permintaan']}
                            rules={rulesGeneral()}
                        >
                        <Input
                         placeholder="Masukan No."
                        />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Type</h5>
                        <Form.Item
                            hasFeedback
                            // name="item_permintaan"
                            // name={['items', index, 'item_permintaan']}
                            rules={rulesGeneral()}
                        >
                        <Input
                         placeholder="Masukan Type"
                        />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Size</h5>
                        <Form.Item
                            hasFeedback
                            // name="item_permintaan"
                            // name={['items', index, 'item_permintaan']}
                            rules={rulesGeneral()}
                        >
                        <Input
                         placeholder="Masukan Size"
                        />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Jumlah</h5>
                        <Form.Item
                            hasFeedback
                            // name="jumlah_permintaan"
                            name={['items', index, 'jumlah_permintaan']}
                            rules={[
                              {
                                required: true,
                                message: "Jumlah permintaan tidak boleh kosong",
                              },
                              {
                                pattern: /^[0-9]*$/,
                                message: "Jumlah permintaan hanya boleh berisi angka",
                              },
                            ]}
                        >
                            <Input 
                             placeholder="Masukan Jumlah"
                            onChange={(e) => handleJumlahItemChange(e.target.value,index)}
                             onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                              e.preventDefault();
                              waw.error('Hanya diperbolehkan memasukkan angka!');
                            }
                          }}
                            />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Satuan</h5>
                        <Form.Item
                            hasFeedback
                            // name="satuan"
                            name={['items', index, 'satuan']}
                            rules={rulesGeneral()}
                        >
                            <Select style={{ width: "100%" }}  placeholder="Masukan Satuan">
                            <Option value="" disabled>
                                -- Pilih --
                            </Option>
                            <Option value="PCS">PCS</Option>
                            <Option value="UNIT">UNIT</Option>
                            <Option value="PACK">PACK</Option>
                            <Option value="LOT">LOT</Option>
                            <Option value="AU">AU</Option>
                            <Option value="TBG">TBG</Option>
                            </Select>
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Banyak PCS</h5>
                        <Form.Item
                            hasFeedback
                            // name="item_permintaan"
                            // name={['items', index, 'item_permintaan']}
                            rules={rulesGeneral()}
                        >
                        <Input
                         placeholder="Masukan PCS"
                        />
                        </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Harga Per Unit</h5>
                        <Form.Item
                            hasFeedback
                            // name="price_per_unit"
                            name={['items', index, 'price_per_unit']}
                            rules={[
                              {
                                required: true,
                                message: "Harga Per Unit tidak boleh kosong",
                              },
                              {
                                pattern: /^[0-9]*$/,
                                message: "Harga Per Unit hanya boleh berisi angka",
                              },
                            ]}
                            initialValue={''}
                            
                        >
                            <InputNumber 
                            style={{width:'100%'}}
                            controls={false}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            onChange={(value) => handlePricePerUnitChange(value ? value : [],index)}
                            placeholder="Masukan Harga Per Unit"
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                e.preventDefault();
                                waw.error('Hanya diperbolehkan memasukkan angka!');
                              }
                            }}
                            />
                            {/* <Input
                            formatter={toRp}
                             onChange={(e) => handlePricePerUnitChange(e.target.value)}
                            /> */}
                            {/* <InputCurrencyHelper
                            callback={(value) => handlePricePerUnitChange(value ? value : [])}
                            values='price_per_unit'
                            /> */}
                        </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Fee</h5>
                        <Form.Item
                            hasFeedback
                            placeholder="PPN"
                            name="ppn"
                            rules={rulesGeneral()}
                        >
                            <InputNumber 
                            style={{width:'100%'}}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            disabled
                            />
                        </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Total Harga</h5>
                        <Form.Item
                            hasFeedback
                            placeholder="Input Total Harga"
                            name={['items', index, 'total_price']}
                            rules={rulesGeneral()}
                        >
                            <InputNumber 
                            style={{width:'100%'}}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            disabled
                            />
                        </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Tanggal Pengiriman</h5>
                        <Form.Item
                            name={['items', index, 'request_date']}
                            rules={[
                                {
                                required: true,
                                message: "Tanggal pengiriman tidak boleh kosong",
                                },
                            ]}
                            initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                            >
                                <DatePicker 
                                picker="date" 
                                onChange={handleDateChange} 
                                placeholder='Pilih Tanggal'
                                format="YYYY-MM-DD"
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Tanggal Distribusi</h5>
                        <Form.Item
                            name={['items', index, 'request_date']}
                            rules={[
                                {
                                required: true,
                                message: "Tanggal distribusi tidak boleh kosong",
                                },
                            ]}
                            initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                            >
                                <DatePicker 
                                picker="date" 
                                onChange={handleDateChange} 
                                placeholder='Pilih Tanggal'
                                format="YYYY-MM-DD"
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Tanggal Redeem</h5>
                        <Form.Item
                            name={['items', index, 'request_date']}
                            rules={[
                                {
                                required: true,
                                message: "Tanggal redeem tidak boleh kosong",
                                },
                            ]}
                            initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                            >
                                <DatePicker 
                                picker="date" 
                                onChange={handleDateChange} 
                                placeholder='Pilih Tanggal'
                                format="YYYY-MM-DD"
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>
                        </Row>
                  </>
                ))}
                <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Keterangan</h5>
                <Form.Item
                    // name={['items', index, 'request_date']}
                    rules={[
                    {
                    required: true,
                    message: "Tanggal keterangan tidak boleh kosong",
                    },
                    ]}      
                >
                <TextArea 
                    autoSize={{ minRows: 10, maxRows: 20 }} 
                    placeholder=" "
                    style={{ height: 300 }} 
                />
                </Form.Item>
                </>
                )}
                  <Form.Item>
                <Button type="primary" onClick={handleAddInputItem} style={{ marginRight: '10px',backgroundColor:'transparent',color:'#03753F' }}>+   TAMBAH ITEM</Button>
                <Button type="danger" onClick={handleRemoveInput} disabled={inputCount === 1}>HAPUS ITEM</Button>
                <div style={{padding:'20px'}}></div>
              </Form.Item> 
                </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className=''>
    <div className='footer-input-submit-wraper'>
        <div className='footer-input-submit-wraper-button'>
        <Row gutter={[16, 16]} justify="space-between" align="top">
        <Col xs={24} sm={12} lg={8}>
            {/* <button className='button-delete-item-wraper' style={{cursor:'pointer'}}>Hapus Item</button> */}
        </Col>
        <Col xs={24} sm={12} lg={8}>
            <button 
                className='button-send-request-wraper' 
                style={{cursor:'pointer'}} 
                htmlType="submit" 
            >KIRIM PENGAJUAN</button>

                    <Modal
                        closable={false}
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        width={900}
                        footer={null}
                    >
                       <>
                             <div>
                             <h1 className='modal-text-action-wraper' style={{textAlign:'center'}}>Konfirmasi Pengajuan</h1>
                             <h1 className='modal-text-action-wraper' style={{textAlign:'center',fontSize:'14px',fontWeight:'400',marginBottom:'25px'}}>Jika Anda memilih Kirim Pengajuan akan dikirim. atau Klik edit untuk mengubah data pengajuan.</h1>
                            </div>
                            <Table
                                dataSource={formData?.items}
                                columns={columns}
                                pagination={false}
                            />
                            <div style={{marginLeft:'auto'}}>
                              <h1 style={{fontWeight:'600',fontSize:'16px',marginTop:'10px'}}>Total : {`Rp.${toRp(formData.total_price_all)}`}</h1> 
                            </div>
                            <Row gutter={[16, 16]} justify="space-between" align="top">
                            <Col xs={24} sm={12} lg={8}>
                                {/* Item 1 - Pojok Kiri */}
                                <div style={{padding: '15px', textAlign: 'center' }}>
                                <button onClick={handleCancel} className='button-save-draft-wraper' style={{cursor:'pointer'}}>EDIT REQUEST</button>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                                {/* Item 2 - Pojok Kanan */}
                                <div style={{padding: '15px', textAlign: 'center' }}>
                                <button key='confirm' onClick={handleSubmit} disabled={loading} className='button-modal-sendreq-wraper' style={{cursor:'pointer'}}>
                                {loadingRequest ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{ fontSize: 24, color: "white" }}
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  "KIRIM REQUEST"
                                )}
                                </button>
                                </div>
                            </Col>
                            </Row>
                            </>
                    </Modal>
            </Col>
            </Row>
        </div>
    </div>
    </div>
    </Form>
    </>
    )}
    {activeTab === 2 && (
    <>
    <Form
    layout='vertical'
    name='tambah-pr'
    form={formTwo} 
    onFinish={onFinish}
    >
    <div className='header-from-input-wraper-content-margin'>
        <div className='header-from-input-wraper-content'>
            <div className='header-from-input-wraper-inline'>
                <div className='header-from-input-wraper-title'>
                <Form.Item
                        hasFeedback
                        placeholder="Input No.Quotation"
                        label="No.Quotation"
                        name="quotation_number"
                        rules={rulesGeneral()}
                    >
                        <Input 
                        value={quotationNumber}
                        // onChange={handleQuotationChange}
                        // onChange={(e) => handleQuotationChange(e.target.value)}
                        onChange={(value) => handleQuotationChange(value ? value : '')}
                         />
                    </Form.Item>
                <Row gutter={[16, 16]} justify="start">
                <Col xs={24} sm={12}>
                <Form.Item
                        hasFeedback
                        placeholder="Input No.PR"
                        label="No.PR"
                        name="no_pr"
                        rules={rulesGeneral()}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                <Form.Item
                        // hasFeedback
                        // placeholder="Input Total Harga"
                        label="Total Harga"
                        // name="total_harga_pr" 
                    >
                      <InputNumber 
                            style={{width:'100%'}}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            value={totalPrice !== null ? totalPrice : ''}
                            disabled
                            />
                        {/* <Input  disabled/> */}
                       
                    </Form.Item>
                </Col>
                </Row>
		            </div>
            </div>
        </div>
    </div>
    <div className='footer-input-submit-wraper-margin'>
    <div className='footer-input-submit-wraper'>
        <div className='footer-input-submit-wraper-button'>
        <Row gutter={[16, 16]} justify="space-between" align="top">
      <Col xs={24} sm={12} lg={8}>
        <button className='button-delete-item-wraper' style={{cursor:'pointer'}}>Hapus Item</button>
      </Col>
      <Col xs={24} sm={12} lg={8}>
      <Form.Item>
          <button  
          type="primary" 
          htmlType="submit" 
          className='button-send-request-wraper' 
          style={{cursor:'pointer'}} >
            Buat Purchase
          </button>
        </Form.Item>
            </Col>
            </Row>
        </div>
    </div>
    </div>
    </Form>
    <Modal
        title="Confirm Data"
        visible={isModalVisibleTwo}
        onOk={handleModalOk}
        onCancel={handleModalCancelTwo}
      >
        <p>Are you sure you want to submit this data?</p>
      </Modal>
     </>
    )}
    </>
  )
}

export default RequestMaterial
